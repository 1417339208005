.nav-desktop{
  display: none;
}
@include media-breakpoint-up(md) {
  .nav-desktop{
  display: flex;
  li{
    flex: 1 1 auto;
    a{
      font-weight: 700;
      color:$black;
      &:hover{
        text-decoration: none;
        color:$blue;
      }
    }
  }
}
}


@include media-breakpoint-only(md) {
  .banner{
    .brand{
      img{
        display: block;
        align:center;
        margin: 0 auto;
        max-width: 225px;

      }
    }
  }
}


.nav-mobile{
  display: flex;
  button{
    padding:0;
    background: none;
  }
  .btn-nav{
    color:$blue;
    border:2px solid $blue;
    padding:.2rem $sm;
    text-transform: uppercase;
    cursor:pointer;
    &:hover{
        background-color: $blue;
        color:#fff;
    }
  }
}

@include media-breakpoint-up(md) {

.nav-mobile{
  display: none;
}

}

.menu-mobile{
  display: none;
}

.fixed-nav{
width: 100%;
 position: fixed;
 top: 0;
 left: 0;
 z-index: 99;
 -webkit-animation: slideDownLinear  .6s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: slideDownLinear  .6s; /* Firefox < 16 */
        -ms-animation: slideDownLinear  .6s; /* Internet Explorer */
         -o-animation: slideDownLinear  .6s; /* Opera < 12.1 */
            animation: slideDownLinear  .6s;

}

.mobile-container{
  display: none;
  .btn-nav{
    color:#fff;
    border:2px solid #fff;
    padding:.2rem $sm;
    text-transform: uppercase;
    cursor:pointer;
    &:hover{
        background-color: $grey;
        color:$blue;
    }
  }
}
.mobile-container.show{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  position:fixed;
  z-index: 999;
  top:0;
  left:0;
  padding:1.4rem;
    @include background-svg-blue;
  -webkit-animation: slideDownLinear  .6s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: slideDownLinear  .6s; /* Firefox < 16 */
        -ms-animation: slideDownLinear  .6s; /* Internet Explorer */
         -o-animation: slideDownLinear  .6s; /* Opera < 12.1 */
            animation: slideDownLinear  .6s;

}
.mobile-container-top{
align-self: flex-end;
  button{
  padding:0;
  background: none;
  }
  .btn-nav{

  }
}
.mobile-container-content{
margin-top:auto;
margin-bottom: auto;
text-align: center;
ul{
  margin:0;
  padding:0;
  li{
    list-style: none;}
    a{
      color:#fff;
      font-size:1.3rem;
      font-weight: 500;
    }
}

}

.mobile-container-bottom{
  .social-footer{

  		li{
  			svg{
  				fill:#fff;
          &:hover{
            fill:#d4d6ec;
            }
          }
  		 }
  	}
}
.hvr-overline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-overline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: $blue;
  height: 4px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-overline-from-left:hover:before, .hvr-overline-from-left:focus:before, .hvr-overline-from-left:active:before {
  right: 0;
}
