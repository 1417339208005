.content-info{
background-color:$black;
color:#CCC;
.legal{
  margin:0;
  padding:0;
  text-align: center;

		li{display:inline-block;
			a{color:#fff;}
		}
		li::after {
			padding:0 5px;
			color:$grey;
    		content: " - ";
		}
		li:last-child:after { content: " "; }

}
}
