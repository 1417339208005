body{
  font-family: $font-family-sans-serif;
  font-weight: 300;
}
a{
  color:$blue;
}

h1,h2,h3,h4,h5,p{
  color:$black;
}

.bg-grey{
  background-color:$grey;
}
.background-white{
  background-color: rgba(255,255,255,.98);
}
.box-shadow{
z-index: 99;
-webkit-box-shadow: 3px 3px 5px -1px rgba(0,0,0,0.3);
-moz-box-shadow: 3px 3px 5px -1px rgba(0,0,0,0.3);
box-shadow: 3px 3px 5px -1px rgba(0,0,0,0.3);
}


@include media-breakpoint-up(sm) {

  .container-fluid{
    padding-left: 1rem;
    padding-right:1rem;
  }


}

@include media-breakpoint-up($xl) {

  .container-fluid{
    padding-left: 2rem;
    padding-right:2rem;
  }

}

// slideDownLinear

@keyframes slideDownLinear {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes slideDownLinear {
    0% {
        -webkit-transform: translateY(-100%);
    }

    100% {
        -webkit-transform: translateY(0%);
    }
}

@keyframes slideDownLinear {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes slideDownLinear {
    0% {
        -webkit-transform: translateY(-100%);
    }

    100% {
        -webkit-transform: translateY(0%);
    }
}
