// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$font-family-sans-serif: "Helvetica Neue Light", HelveticaNeue-Light, "Helvetica Neue", 'Source Sans Pro',sans-serif;
// colors

$black: #444444;
$blue: #0046BD;
$grey: #F3F3F3;

// sizes

$xs: .5rem;
$sm: 1rem;
$md: 1.3rem;
$lg: 1.6rem;
$xl: 2rem;

// mixins

@mixin background-svg-blue{
background-color: #0046BD;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2312498f'/%3E%3Cstop offset='1' stop-color='%23002d69'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1' gradientTransform='rotate(13,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%235c6880' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%235c6880' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2' gradientTransform='rotate(0,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%235c6880' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%235c6880' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.8'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
}
