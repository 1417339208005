.hero{
  min-height: 450px;
  @include media-breakpoint-up(md) {
  min-height:500px;
  }
  display: flex;
  flex-wrap: wrap;
  background: url("../images/blok-tech-1-mobile.jpg") no-repeat;
  background-position: 50% 0;
  background-attachment: fixed;
  background-size: cover;
  @include media-breakpoint-up(sm) {
  background: url("../images/blok-tech-1.jpg") no-repeat;
  background-position: 50% 0;
  background-size: cover;
  }

}

.hero--content{
  align-self: center;
  text-align: center;
  padding:1rem;
  @include media-breakpoint-up(md) {
      padding:4rem;
  }
  @include media-breakpoint-up(lg) {
      padding:6rem;
  }
  @include media-breakpoint-up(xl) {
      padding:9rem;
  }

  @media (min-width: 1600px){
            padding:11rem;
  }
  h1{
    color:#fff;
    font-size: 1.6rem;
    font-weight: 300;
    @include media-breakpoint-up(sm) {
      font-size: 1.7rem;

    }
    @include media-breakpoint-up(md) {
      font-size: 2.1rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.6rem;
    }
  }

  .hero--call{
    display: flex;
    flex-wrap: wrap;
    margin-top:40px;
    padding:0 1rem;
    @include media-breakpoint-up(sm) {
          padding:0 6rem;
    }
    @include media-breakpoint-up(lg) {
          padding:0 16rem;
    }

    .hero--button{
      flex: 0 0 100%;
      @include media-breakpoint-up(sm) {
      }
      .hero--btn{
        background: none;
        border:3px solid #fff;
        color:#fff;
        padding:1.2rem 2.7rem;
        cursor:pointer;
        position:relative;
        &:hover{
          background-color: rgba(255,255,255,1);
          color:$blue;
        }
      }

    } // .hero--button

    .hero--btn:after{
            background: url('../images/caret.svg') center no-repeat;
              height: 19px;
              width: 19px;
              position: absolute;
              top:35%;
              right: 5%;
              content: "";
    }


  } // .hero--call

} // .hero


/** BLOCK SECITON **/

.blok--section{
  padding:3.3rem 0;

  .blok--section--media{
    svg{
      max-height: 140px;
      display: block;
      margin: 0 auto;
      // svg from header section
      fill: url(#blok-gradient) #134d97;
      &:hover{
        fill:#134d97;
      }
    }
  }
  .blok--section--intro{
        margin:1rem 1.3rem;
        text-align: center;

        h2{
          color:$black;
          line-height: 110%;
          margin-bottom: 1rem;
          font-size: 1.6rem;
        }
  }
  .blok--section--content{

    margin:.5rem 0;
    text-align: center;
    strong{
      font-size: 900;
      color:$blue;
    }
    h2{
      font-size: 2.2rem;
    }
    p{
      font-size: 1.1rem;
    }
  }

}


.blok--hero{
  width:100;
  padding:3rem 0;
  margin: 0 auto;
  @include background-svg-blue;

    .blok--section--hero{
      width: 95%;
      margin: 0 auto;
          @include media-breakpoint-up(md) {
            width: 80%;
          }
          @include media-breakpoint-up(lg) {
            width: 65%;
          }

      h2{
          letter-spacing: -1px;
          text-align: center;
          line-height: 100%;
          margin-bottom: 1rem;
          color:#fff;
        }

        p{
          color:#fff;
          text-align: center;
        }
      }

    .blok--hero--item{

      text-align: center;
      border:3px solid $grey;
      h3{
        color:#fff;
        font-weight: 300;
        font-size: 2.2rem;
      }
      p{
        color:#fff;
      }
      button{
        width: 100%;
        border: none;
        background-color: #fff;
        color:$blue;
        font-weight: 700;
        cursor: pointer;
      }
    }
}


.blok--item{
  h3{
    font-size: 1.8rem;
    line-height: 100%;
    font-weight: 700;
  }
  span.underline{
    border-bottom: 2px solid #A4143E;
    font-weight: 500;
  }
  .blok--media{
    svg{
      max-height: 140px;
      display: block;
      margin: 0 auto;
      // svg from header section
      fill: url(#blok-gradient-2) #A4143E;
      &:hover{
        fill:#A4143E;
      }
    }
  }
}


.hero--drinks{
  min-height: 380px;
  @include media-breakpoint-up(md) {
  min-height:430px;
  }
  display: flex;
  flex-wrap: wrap;
  background: url("../images/blok-drinks-1.jpg") no-repeat;
  background-position: 50% 0;
    background-attachment: fixed;
  background-size: cover;

  .hero--drinks--content{
    align-self: center;
    text-align: center;
    width: 95%;
    margin: 0 auto;
    @include media-breakpoint-up(md) {
    width: 80%;
    }
    h4{
    color:#fff;
    font-size: 1.7rem;
    font-weight: 300;
    @include media-breakpoint-up(md) {
    font-size: 2.3rem;
    }
    }

  }

}

.blok--confidence{

  background: url("../images/confidence-shield.svg") no-repeat,  #F3F3F3;
  background-position: 50% 0;
  background-size: cover;
}
.blok--cells{
  .confidence-1{background-color: #AE384F;}
  .confidence-2{background-color: #A51A40;}
  .confidence-3{background-color: #6B184C;}
  th{
      color:#fff;
      text-align: center;
      font-size: 1.7rem;
      font-weight: 300;
  }
  tr{
    background-color:#F9F9F9;
  }
  tr:nth-child(even){
    background-color:#fff;
  }
  tr:nth-child(odd){
    background-color:rgba(252,235,249,.6);
  }
}

.btn--blok{
  margin: 0 auto;
  background: none;
  text-align: center;
  display: block;
  font-weight: 700;
  padding:1.2rem 2.1rem;
  cursor:pointer;
  line-height: 100%;

}
.btn--drinks{

  border:3px solid #A4143E;
  font-size:1.2rem;
  color:#A4143E;
  &:hover{
    background-color: #A4143E;
    color:#fff;
    span{
      color:#e9e9e9;
    }
  }
  span{
    font-size: .9rem;
    color:$black;
    font-weight: 300;
    display: block;

  }
}

.btn--car{
  border:3px solid $blue;
  font-size:1.2rem;
  color:$blue;
  &:hover{
    background-color: $blue;
    color:#fff;
    span{
      color:#e9e9e9;
    }
  }
  span{
    font-size: .9rem;
    color:$black;
    font-weight: 300;
    display: block;

  }

}

.blok--team{

  background: url("../images/blok-who-we-are.jpg") no-repeat;
  background-position: 50% 0;
  background-size: cover;
  h2,p{color:#fff;}
  strong{
    font-weight: 700;
  }
}


.social-footer{
  display: block;
  margin:0 auto;
  text-align: center;
  padding:0;
		li{
      display: inline-block;
			svg{
				fill:$blue;
				width: 50px;
				display: block;
        &:hover{
          fill:#d4d6ec;
          }
        }

		}

	}

.page-header {

    h1 {
        margin:1rem 0;

    }
}

.blok--page {

  h2 {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 1rem;
  }
}
